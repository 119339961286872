/* .formDash
{
	background-color: #cbdfe8;
	border-radius: 5px;
	border: 2px solid #97c3d8;
    max-width:1200px;
    margin:auto;
    margin-top: 25px;
    width: 95%;
    margin:auto;
    margin-top: 25px;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
} */

.formDash
{
	background-color: #ffffff;
	border-radius: 5px;
	border: 2px solid #e3e8ea;
    max-width:1200px;
    margin:auto;
    margin-top: 25px;
    width: 95%;
    margin:auto;
    margin-top: 25px;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    margin-bottom: 30px;
}

h2
{
	text-align: center;
	font-size: 2em;
}

form label
{
    font-size: 1.4em;
}



input[type="submit"]
{
	background-color:#f2f6f8;
    color: black;
	min-width: 140px;
}
input[type="submit"]:hover
{
	background-color:#6db0cf;
	border: 1px solid #155776;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

}

input[type="submit"]:active
{
	background-color:#4892b4;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5), 0 6px 20px 0 rgba(0,0,0,0.19);

}

.center
{
	text-align:center;
	margin:auto;
}

.errorMsg
{
	color:red;
}

#dash
{
    width: 100%;
}

#pointsForm
{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.pointsSubForm
{
	background-color: #80beda58;
	border: 2px solid #155776;
    flex: 1 1 auto;
    width:25%;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    word-wrap: break-word; /* Deprecated, but still supported */
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;

}
.subForm h3, .pointsSubForm h3, #eboard h3
{
	font-size: 1.9em;
    text-align: center;
}
.attendanceForm {
    display:block;
    background-color: #80beda58;
    border: 2px solid #155776;
    width: 100%;
    margin: 15px 15px 15px 15px;
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1), 0 6px 20px 0 rgba(0,0,0,0.19);

}

#attendanceInput
{
    max-width: 800px;
    margin:auto;
}


@media only screen and (max-width:500px)
{
    .subForm h3, .pointsSubForm h3, #eboard h3
    {
        font-size: 1.3em;
        text-align: center;
    }
    .pointsSubForm h2
    {
        font-size: 1.5em;
        text-align: center;
    }
}