#cabinetPointsBreakdown
{
    background-color: #80beda58;
    border-radius: 5px;;
    margin:auto;
    width: 100%;
    margin: 15px 15px 15px 15px;
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1), 0 6px 20px 0 rgba(0,0,0,0.19);
}
#cabinetPointsBreakdown h2
{
    text-align: center;
    font-size: 2.1em;
}
#cabinetPointsBreakdown h3
{
    text-align: center;
    font-size: 1.8em;
}
#cabinetPointsBreakdown h4
{
    text-align: center;
    font-size: 1.5em;
}
#cabinetPointsDescription
{
    text-align: center;
}

.strike
{
    color:red;
}

