.header {
	background-color: #155776;
	padding: 10px 0;
}

.nav-bar {
	display: flex;
	justify-content: center;
}

.nav-bar ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
}

.nav-bar li {
	margin: 0 15px;
}

.nav-bar a {
	color: white;
	text-decoration: none;
	font-size: 18px;
}

.nav-bar a:hover {
	color: #ddd;
}