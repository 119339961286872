

h2
{
	text-align: center;
	font-size: 2em;
}

form label
{
    font-size: 1.4em;
}



input[type="submit"]
{
	background-color:#f2f6f8;
	min-width: 140px;
}
input[type="submit"]:hover
{
	background-color:#6db0cf;
	border: 1px solid #155776;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

}

input[type="submit"]:active
{
	background-color:#4892b4;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5), 0 6px 20px 0 rgba(0,0,0,0.19);

}



.errorMsg
{
	color:red;
}




.subForm h3
{
	font-size: 1.9em;
    text-align: center;
}

.center
{
	text-align:center;
	margin:auto;
}
#eboardContainer
{
    margin:auto;
    margin-top: 5%;
    width:85%;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
}
#eboard
{
    padding-left: 10px;
    padding-right: 10px;
    
}


input[type="checkbox"]
{
    width:1em;
}