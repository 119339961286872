input[type="text"]
{
    width: 95%;
    margin-left: 10px;
}
.attendanceForm label
{
    margin-left: 10px;
}

select 
{
    width: 100%;
}

.hidden 
{
    display:none;
}

#backToUserEmail
{
    font-size: 1em;
    min-width: 5em;
    border-color: #155776;
    margin-bottom: .5em;
}