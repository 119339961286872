body {
  margin: 0;
  src: url("https://fonts.google.com/specimen/Reddit+Mono");
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f1f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input, select
{
  font-family: Montserrat, sans-serif;
}
