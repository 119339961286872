h3{
    text-align:center;
}
.head
{
    background-color: #155776;
}
.even
{
    background-color: #c5e5f3;
}
.odd
{
    background-color: #eef0ee;
}

#approvedCabinetContainer table
{
    margin:auto;
    margin-top: 5%;
    width:85%;
    border-collapse:collapse;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1), 0 6px 20px 0 rgba(0,0,0,0.19);
    overflow-x: auto;
}