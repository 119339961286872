#EventsAttended
{
    width: 100%;
    margin-top: 40px;
}

#EventsAttended tr{
    height: 2em;
}

#EventsAttended thead
{
    background-color: #155776;
}

#EventsAttended td
{
    width: 33%;
}

#EventsAttended h3
{
    margin-bottom: -1em;
}

#EventsAttended table
{
    margin-bottom: 2em;
}

#EventsAttended p
{
    margin-bottom: 0;
}